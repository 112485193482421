/* eslint-disable @typescript-eslint/no-empty-function */
import { jsPDF } from "jspdf";
import { CargoData } from "@/domain/entities/Cargo";
import DateTimeLabelPlane from "@/app/infrastructures/misc/common-library/DateTimeLabelPlane";
import uuid from "@/app/infrastructures/misc/common-library/Uuid";
import { convertQRCode } from "@/app/ui/modules/receipt/module";
import { layoutTransitPlaneLabel } from "./cargo-module"

const labelPlanePdf = async (
  data: CargoData,
  logo: string,
  barcode: string
) => {
  const doc: any = new jsPDF("p", "mm", "a6", true);
  const lengthOfTransit = data.flightsDetail.length;
  const generate = async (pieceCount: number, qrcode: string) => {
    logo
      ? doc.addImage(logo, "PNG", 30, 2, 45, 14, "logo", "FAST", 0)
      : doc.text("", 1, 1);
    doc.addImage(barcode, "PNG", 4, 130, 97, 17, "barcode", "FAST", 0);
    doc.addImage(qrcode, "PNG", 2, 15, 48, 43, uuid(), "FAST", 0);

    doc.setLineWidth(0.3);
    doc.rect(1, 1, 103, 146);

    doc.line(1, 17, 104, 17);
    doc.line(51, 17, 51, 56);
    doc.line(1, 56, 104, 56);
    doc.line(1, 63, 104, 63);
    doc.line(1, layoutTransitPlaneLabel(lengthOfTransit).topLineHeight, 104, layoutTransitPlaneLabel(lengthOfTransit).topLineHeight); // enhance
    doc.line(1, 83, 104, 83);
    doc.line(51, 83, 51, 122);
    doc.line(1, 88, 104, 88);
    doc.line(1, 103, 104, 103);
    doc.line(1, 108, 104, 108);
    doc.line(1, 122, 104, 122);

    // Gross Weight Per Piece
    doc.setFont("times", "bold");
    doc.setTextColor("#000000");
    doc.setFontSize(12);
    doc.text("Gross weight per Piece", 56, 21);

    doc.setFontSize(32);
    doc.text("KG", 80, 44);

    // AWB Number
    doc.setFontSize(12);
    doc.text("Air Waybill No", 36, 61);

    doc.setFontSize(layoutTransitPlaneLabel(lengthOfTransit).fontCargoNumber);
    doc.text(data.cargoNumber, 52, layoutTransitPlaneLabel(lengthOfTransit).topCargoNumber, "center");

    // Flight No. & Date
    let orderDetail = 0;
    for (const item of data.flightsDetail) {
      doc.setFontSize(layoutTransitPlaneLabel(lengthOfTransit).fontTransitList);
      doc.text(
        `${item.cfNumber} / ${DateTimeLabelPlane(
          item.cfEstDepartureTime
        )} (${item.cfAirportOrigin})`,
        52,
        layoutTransitPlaneLabel(lengthOfTransit).topTransitHeight + orderDetail,
        "center"
      );
      orderDetail += 3;
    }

    // Destination
    doc.setFontSize(12);
    doc.text(`Destination`, 15, 86.5);
    doc.setFontSize(36);
    doc.text(
      data.cargoType === "plane"
        ? data.airportDestinationCityCode
        : data.destinationCityCode,
      25,
      100,
      "center"
    );

    // No Of Pieces
    doc.setFontSize(12);
    doc.text(`No Of Pieces`, 65, 87);
    doc.setFontSize(24);
    doc.text(`${pieceCount}/${data.cargoActualPiece}`, 75, 98, "center");

    // Total Weight
    doc.setFontSize(12);
    doc.text(`Total Weight`, 15, 107);
    doc.setFontSize(24);
    const totalWeight = Number(
      data.totalActualCargoGrossWeight.replace(",", ".")
    ).toFixed(1);
    doc.text(String(totalWeight), 25, 118, "center");

    // Origin
    doc.setFontSize(12);
    doc.text(`Origin`, 70, 107);
    doc.setFontSize(24);
    doc.text(data.originCityCode, 66, 118);

    // Agent Name
    doc.setFontSize(20);
    doc.text(`LION PARCEL ${data.originCityCode}`, 50, 129, "center");
  };
  
  const transitsOrigin = []
  const transitFlight = []
  for (const item of data.flightsDetail) {
    transitsOrigin.push(`${item.cfAirportOrigin}`);
    transitFlight.push(item.cfNumber)
  }
  const transitOriginQr = transitsOrigin.length > 1 ? transitsOrigin.slice(1).join(",") : "";
  const transitFlightQr = transitFlight.join(",")
  let noPieces = 0;
  // duplicate label match number of total pieces
  for (let i = 0; i < data.cargoActualPiece; i++) {
    if (i > 0) {
      doc.addPage("a6", "p");
    }
    noPieces = i + 1;
    const newCombineQRText = `${data.cargoNumber}#${ data.cargoNumber }${noPieces.toString().padStart(5, "0")}#${data.originCityCode}#${data.destinationCityCode}#${transitOriginQr}#${data.totalActualCargoGrossWeight}#${ data.cargoActualPiece }#${data.nGenCreatedAt || data.createdAt}#${transitFlightQr};`;
    const newQRCode = await convertQRCode(newCombineQRText);
    generate(noPieces, newQRCode);
  }

  doc.setProperties({
    title: `Cargo Label.pdf`
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
};

export default labelPlanePdf;
