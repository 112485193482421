/* eslint-disable @typescript-eslint/no-empty-function */
import { dateToDateString, ellipsisString } from "@/app/infrastructures/misc/Utils";
import formatInputFloat from "@/app/infrastructures/misc/common-library/FormatInputFloat";
import { AccountController } from "@/app/ui/controllers/AccountController";
import {
    ColumnManifestData,
    OptionsClass,
    SectionManifestData
} from "@/domain/entities/MainApp";
import jsPDF from "jspdf";
import { ColumnInput } from "jspdf-autotable";
import "jspdf-autotable";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { createI18n } from "vue-i18n";

let translate: any = () => {};

const   margin = {
    left: 10,
    right: 10,
    top: 5,
    bottom: 21
};
let doc: any = new jsPDF(),
    roleType = "",
    isInternal = false,
    title = "",
    headerSection: OptionsClass[][] = [],
    columnData: ColumnInput[] = [],
    x = margin.left,
    y = margin.top,
    xx = margin.left,
    currentSection = 1,
    nextSection = 1,
    startY = margin.top + 23,
    startXSignature = 90;

const headerSectionProcess = async (params: SectionManifestData) => {
    // set accound data
    params.headerDetailData.accountData = AccountController.accountData;
    isInternal = AccountController.accountData.isInternalAccount;
    roleType = isInternal ? params.headerDetailData.accountData.account_type : params.headerDetailData.accountData.account_type_detail?.type;

    // manifest title
    title = `${params.featureName}`;

    /* HEADER SECTION DATA */
    // array in array for divide header data. ex: [[first-section],[second-section],[third-section],[...-section]]
    const headerSectionsData = (): OptionsClass[] => [
        new OptionsClass({
            name: translate("Kota Asal"),
            value: params.headerDetailData.originCity,
            key: "originCity"
        }),
        new OptionsClass({
            name: translate("Kota Tujuan"),
            value: params.headerDetailData.destinationCity,
            key: "destinationCity"
        }),
        new OptionsClass({
            name: translate("Tipe Kargo"),
            value: params.headerDetailData.cargoType,
            key: "cargoType"
        }),
        new OptionsClass({
            name: translate("Nama Partner"),
            value: ellipsisString(params.headerDetailData.partnerName, 41),
            key: "partnerName"
        }),
        new OptionsClass({
            name: translate("Tanggal Keberangkatan"),
            value: dateToDateString(params.headerDetailData.departureDate),
            key: "departureDate"
        }),
        new OptionsClass({
            name: translate("Tanggal Kedatangan"),
            value: dateToDateString(params.headerDetailData.arrivalDate),
            key: "arrivalDate"
        }),
        new OptionsClass({
            name: translate("Komoditas Kargo"),
            value: params.headerDetailData.cargoCommodity,
            key: "cargoCommodity"
        }),
        new OptionsClass({
            name: translate("Produk Kargo"),
            value: params.headerDetailData.cargoProduct,
            key: "cargoProduct"
        }),
        new OptionsClass({
            name: translate("Total STT"),
            value: String(params.data.length) + " STT",
            key: "totalStt"
        }),
        new OptionsClass({
            name: translate("Total Koli"),
            value: formatInputFloat(params.headerDetailData.totalPiece),
            key: "totalPiece"
        }),
        new OptionsClass({
            name: translate("Total Berat"),
            value: `${params.headerDetailData.totalActualCargoGrossWeight} Kg`,
            key: "totalGrossWeight"
        }),
        new OptionsClass({
            name: translate("Total Berat Dimensi"),
            value: `${params.headerDetailData.totalActualCargoVolumeWeight} Kg`,
            key: "totalVolumeWeight"
        }),
    ];
    // get & sort list header section data
    const sectionData = [];
    for (const section of params.headerSection) {
        for (const detail of headerSectionsData()) {
            if (detail.key === section) {
                sectionData.push(detail);
            }
        }
    }
    // divide header section data to some row
    const row =
        Math.ceil(params.headerSection.length / params.totalColumnHeader) +
        (params.headerSection.length <= params.totalColumnHeader ? 1 : 0);
    let i = 0;

    do {
        headerSection.push(sectionData.splice(0, row));
        i += 1;
    } while (i <= row);

    startY += headerSection[0].length * 5;
    /* END HEADER SECTION DATA */
};

const tableDataProcess = (params: SectionManifestData) => {
    /* TABLE DATA */
    const tableColumnsData = [
        {
            header: translate("No. Bag"),
            dataKey: "bagNo"
        },
        {
            header: translate("No. STT"),
            dataKey: "sttNo"
        },
        {
            header: translate("Berat Kotor"),
            dataKey: "totalGrossWeight"
        },
        {
            header: translate("Dest."),
            dataKey: "destinationCity"
        }
    ];

    // get & sort list column data
    for (const section of params.columns) {
        for (const detail of tableColumnsData) {
            if (detail.dataKey === section) {
                columnData.push(detail);
            }
        }
    }
    // add propery no each rows
    if (params.columns.length) {
        columnData.unshift({
            header: "No.",
            key: "no"
        });
    }
    //   add column No.
    params.data = params.data.map(
        (item: ColumnManifestData, index: number) =>
            new ColumnManifestData({
                ...item,
                no: index + 1
            })
    );
    /* END TABLE DATA */
};

const signatureProcess = (params: SectionManifestData) => {
    const spaceSignature = 10;
    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height || pageSize.getHeight();

    if (currentSection === 1) {
        nextSection = currentSection;
        startY = doc.lastAutoTable.finalY + 5;
    } else {
        startY = pageHeight - margin.top - spaceSignature;
    }

    if (params.signature) {
        doc.setFont("Poppins", "normal");
        doc.setFontSize(8);
        doc.setTextColor("#4d4d4d");
        startXSignature += 51;
        doc.text(translate("Kurir"), startXSignature, startY - 2);
        doc.setFont("Poppins", "normal");
        startXSignature += 28;
        doc.text("Operation Supervisor", startXSignature, startY - 2);
        startXSignature -= 77;

        startXSignature += 38;
        doc.text(
            "(...........................................)",
            startXSignature,
            startY + spaceSignature
        );
        startXSignature += 38;
        doc.text(
            "(.................................................)",
            startXSignature,
            startY + spaceSignature
        );
    }
};

const onResetData = () => {
    roleType = "internal";
    isInternal = false,
    title = "";
    headerSection = [];
    columnData = [];
    x = margin.left;
    y = margin.top;
    xx = margin.left;
    currentSection = 1;
    nextSection = 1;
    startY = margin.top + 23;
    startXSignature = 90;
};

export const generateManifestCargoV2 = async (
    params: SectionManifestData,
    barcode: any,
    qrcode: any
): Promise<void> => {
    const i18nInstance = createI18n({
      legacy: false,
      locale: AccountController.accountData.account_type_detail.countryCode.toLowerCase(),
      fallbackLocale: "id",
      globalInjection: false,
      messages: {
        en,
        id,
        my
      }
    });
    translate = i18nInstance.global.t;

    doc = new jsPDF("p", "mm", "a4", true);

    headerSectionProcess(params);
    tableDataProcess(params);

    // logo
    const importLogo = await import(
        `@/app/ui/assets/images/logo-${roleType}.png`
    );
    const logo = importLogo.default;

    // number of table sections in the page
    const sections = 2;

    // space between each section
    const spacing = 5;

    // calculate each section width
    const widthPaper = doc.internal.pageSize.getWidth();
    const maxRight = widthPaper - margin.right;
    const printWidht = doc.internal.pageSize.width - (margin.left + margin.right);
    const sectionWidth = (printWidht - (sections - 1) * spacing) / sections;
    // add an initial empty page that will be delete later,
    // it is needed for the first setPage(previous_page) call
    doc.addPage();

    doc.autoTable({
        theme: "plain",
        body: params.data,
        columns: columnData,
        styles: {
            font: "Poppins",
            halign: "left",
            fontSize: 6,
            textColor: "#4D4D4D"
        },
        headStyles: {
            fontStyle: "bold",
            fillColor: "#F5F6F7",
            cellPadding: {
                top: 2,
                right: 1,
                bottom: 2,
                left: 1
            }
        },
        columnStyles: {
            0: { cellWidth: "wrap" }
        },
        bodyStyles: {
            fontStyle: "normal",
            valign: "middle",
            cellPadding: {
                top: 1.2,
                right: 1.3,
                bottom: 1.2,
                left: 1.3
            },
            cellWidth: "auto",
            lineColor: "#4D4D4D"
        },
        tableWidth: sectionWidth,
        margin: {
            left: margin.left + (nextSection - 1) * (sectionWidth + spacing),
            top: startY,
            bottom: margin.bottom
        },
        startY,
        rowPageBreak: "avoid", // avoid breaking rows into multiple sections
        didDrawPage({ table }: any) {
            /* HEADER SECTION */
            if (doc.internal.getNumberOfPages() === 2 && nextSection === 1) {
                // get logo
                const logoProperties = doc.getImageProperties(logo);
                const logoAspectRatio = logoProperties.height / logoProperties.width;

                const liloLogoWidth = 40;
                doc.addImage(
                    logo,
                    "PNG",
                    maxRight - liloLogoWidth,
                    y,
                    liloLogoWidth,
                    logoAspectRatio * liloLogoWidth,
                    `${roleType}-logo`,
                    "FAST"
                );

                // qr and barcode
                doc.setLineWidth(0.1);
                doc.line(155, 21, 155, 53);
                doc.addImage(qrcode, "PNG", 159, 20, 18.5, 18.5, "qrcode", "FAST", 0);
                doc.addImage(barcode, "PNG", 159, 42, 41, 12, "barcode", "FAST", 0);

                // title
                y += 6;
                doc.setFont("Poppins", "bold");
                doc.setFontSize(14);
                doc.setTextColor("#4d4d4d");
                doc.text(title, x, y);
                doc.setFont("Poppins", "normal");
                y += 6
                // lines top
                doc.setFillColor("#e0e0e0");
                doc.rect(x, y, 190, 0.2, "F");

                y += 2; // add space after title

                // header detail data
                const valueGap = 35;
                const sectionGap = 80;
                for (const data of headerSection) {
                    doc.setFontSize(7);
                    doc.setTextColor("#4d4d4d");
                    doc.setFillColor("#ebecf0");

                    for (const [idx, item] of data.entries()) {
                        y += 5;
                        doc.setFont("Poppins", "normal");
                        doc.text(item.name, x, y);
                        xx += valueGap;
                        doc.setFont("Poppins", "normal");
                        doc.text(": " + item.value, xx, y);

                        if (idx === data.length - 1) {
                            x += sectionGap;
                            xx = x;
                            y -= data.length * 5;
                        } else {
                            xx -= valueGap;
                        }
                    }
                }
            }

            currentSection = nextSection;
            nextSection = (nextSection % sections) + 1;

            // set left margin which will controll x position of next section
            const shift = (nextSection - 1) * (sectionWidth + spacing);
            table.settings.margin.left = margin.left + shift;

            // if next section is not the fist, move to previous page so when
            // autoTable calls addPage() it will still be the same current page
            if (doc.internal.getNumberOfPages() === 2 && nextSection === 1) {
                table.settings.margin.top = 15;
            }
            if (nextSection > 1) {
                doc.setPage(doc.internal.getNumberOfPages() - 1);
            }

            /* END HEADER SECTION */
        },
        willDrawCell: (data: any) => {
            // add borders bottom each column
            doc.setDrawColor("#E0E0E0"); // set the border color
            doc.setLineWidth(0.2); // set the border with

            if (data.section === "head") {
                // draw top border each row
                doc.line(
                    data.cell.x + data.cell.width,
                    data.cell.y,
                    data.cell.x,
                    data.cell.y
                );
            }

            if (data.column.index === 0) {
                // draw left border
                doc.line(
                    data.cell.x,
                    data.cell.y + data.cell.height,
                    data.cell.x,
                    data.cell.y
                );
            }
            else if (data.column.index === columnData.length - 1) {
                // draw right border
                doc.line(
                    data.cell.x + data.cell.width,
                    data.cell.y,
                    data.cell.x + data.cell.width,
                    data.cell.y + data.cell.height
                );
            }
            if (
                data.column.dataKey === "destinationCity" &&
                data.row.section === "body"
            ) {

                doc.setFillColor("#ebecf0");
                data.row.cells["destinationCity"].text
                    .filter((item: any) => item !== "")
                    .map((_: any, index: number) => {
                        doc.roundedRect(
                            data.row.cells["destinationCity"].x + 0.6, //x
                            data.row.cells["destinationCity"].y +
                            data.row.cells["destinationCity"].contentHeight /
                            (2.6333 +
                                1.75 *
                                data.row.cells["totalGrossWeight"].text.filter(
                                    (item: any) => item !== ""
                                ).length) +
                            (0.0083 + 5.675 * index), // y
                            data.row.cells["destinationCity"].contentWidth - 1, // w
                            2, // h
                            0.5,
                            0.5,
                            "F"
                        );
                    });
                doc.setFillColor("#fff");
            }

            // draw bottom border each row
            doc.line(
                data.cell.x,
                data.cell.y + data.cell.height,
                data.cell.x + data.cell.width,
                data.cell.y + data.cell.height
            );
        },
        didParseCell: (data: any) => {
            for (const body of data.table.body) {
                // parse string for weight with Kg
                if (data.column.dataKey === "totalGrossWeight") {
                    body.cells["totalGrossWeight"].text = [
                        `${formatInputFloat(body.raw["totalGrossWeight"])} Kg`
                    ];
                }
            }
        }
    });

    // activate last page for further printing
    doc.setPage(doc.internal.getNumberOfPages());

    signatureProcess(params);

    // delete unused empty page
    doc.deletePage(1);

    doc.setProperties({
        title
    });

    doc.autoPrint();

    window.open(doc.output("bloburl"), "_blank");
    onResetData();
};
