/* eslint-disable @typescript-eslint/no-empty-function */
import { jsPDF } from "jspdf";
import { CargoData } from "@/domain/entities/Cargo";
import uuid from "@/app/infrastructures/misc/common-library/Uuid";
import { convertJsBarcode, convertQRCode } from "@/app/ui/modules/receipt/module";
import { formatDateNormal } from "@/app/infrastructures/misc/Utils";
const labelPlanePdfV2 = async (
    data: CargoData,
    logo: string
) => {
    const doc: any = new jsPDF("p", "mm", "a6", true);
    const generate = async (pieceCount: number, qrcode: string, newBarcode: string) => {
        logo
            ? doc.addImage(logo, "PNG", 75, 3, 25, 7, uuid(), "FAST", 0)
            : doc.text("", 1, 1);
        doc.addImage(qrcode, "PNG", 1, 1.5, 35, 35, uuid(), "FAST", 0);

        // WEIGHT (KG)
        doc.setFont("Poppins", "bold");
        doc.setFontSize(10);
        doc.setTextColor("#6a6a6a");
        doc.text("WEIGHT (KG)", 35, 6.5);

        // Total Weight ${totalActualCargoGrossWeight}
        doc.setFont("Poppins", "bold");
        doc.setFontSize(21);
        doc.setTextColor("#000000");
        const totalWeight = Number(
            data.totalActualCargoGrossWeight.replace(",", ".")
        ).toFixed(1);
        doc.text(`         /${totalWeight}`, 35, 15);

        // PIECES
        doc.setFont("Poppins", "bold");
        doc.setFontSize(10);
        doc.setTextColor("#6a6a6a");
        doc.text("PIECES", 35, 24.5);

        // Actual Piece ${pieceCount}/${cargoActualPiece}
        doc.setFont("Poppins", "bold");
        doc.setFontSize(21);
        doc.setTextColor("#000000");
        doc.text(`${pieceCount}/${data.cargoActualPiece}`, 35, 33);

        // DESTINATION
        doc.setFont("Poppins", "bold");
        doc.setFontSize(10);
        doc.setTextColor("#6a6a6a");
        doc.text("DESTINATION", 102, 16, "right");

        //DESTINATION 3LC
        doc.setFont("Poppins", "bold");
        doc.setFontSize(data.cargoActualPiece > 99 ? 50 : 60);
        doc.setTextColor("#000000");
        doc.text(data.cargoType === "plane"
            ? data.airportDestinationCityCode
            : data.destinationCityCode, 101.5, 33.5, { align: "right", charSpace: 1 });

        //AWB NUMBER ${cargoNumber}
        doc.setFont("Poppins", "bold");
        doc.setFontSize(35);
        doc.setTextColor("#000000");
        doc.text(data.cargoNumber, 3, 48);

        // LINE 1
        doc.line(3, 52, 102, 52);

        // ORIGIN
        doc.setFont("Poppins", "bold");
        doc.setFontSize(10);
        doc.setTextColor("#6a6a6a");
        doc.text("ORIGIN", 3, 57);

        // ORIGIN CITY
        doc.setFont("Poppins", "bold");
        doc.setFontSize(46);
        doc.setTextColor("#000000");
        doc.text(data.originCityCode, 3, 70);

        // FLIGHT NO ORIGIN
        doc.setFont("Poppins", "bold");
        doc.setFontSize(32);
        doc.setTextColor("#000000");
        doc.text(data.flightsDetail.length ? data.flightsDetail[0]?.cfNumber : "", 53, 64);

        // FLIGHT DATE ORIGIN
        doc.setFont("Poppins", "bold");
        doc.setFontSize(19);
        doc.setTextColor("#000000");
        doc.text(data.flightsDetail.length ? formatDateNormal(data.flightsDetail[0]?.cfEstDepartureTime, "DD MMM HH:mm") : "", 53, 71);

        // LINE 2
        doc.line(3, 73, 102, 73);

        // TRANSIT-1
        doc.setFont("Poppins", "bold");
        doc.setFontSize(10);
        doc.setTextColor("#6a6a6a");
        doc.text("TRANSIT-1", 3, 77);

        // TRANSIT-1 CITY
        doc.setFont("Poppins", "bold");
        doc.setFontSize(46);
        doc.setTextColor("#000000");
        doc.text(data.flightsDetail.length > 1 ? data.flightsDetail[1]?.cfAirportOrigin : "", 3, 91);

        // FLIGHT NO TRANSIT-1
        doc.setFont("Poppins", "bold");
        doc.setFontSize(32);
        doc.setTextColor("#000000");
        doc.text(data.flightsDetail.length > 1 ? data.flightsDetail[1]?.cfNumber : "", 53, 85);

        // FLIGHT DATE TRANSIT-1
        doc.setFont("Poppins", "bold");
        doc.setFontSize(19);
        doc.setTextColor("#000000");
        doc.text(data.flightsDetail.length > 1 ? formatDateNormal(data.flightsDetail[1]?.cfEstDepartureTime, "DD MMM HH:mm") : "", 53, 92);

        // LINE 3
        doc.line(3, 94, 102, 94);

        // TRANSIT-2
        doc.setFont("Poppins", "bold");
        doc.setFontSize(10);
        doc.setTextColor("#6a6a6a");
        doc.text("TRANSIT-2", 3, 98);

        // TRANSIT-2 CITY
        doc.setFont("Poppins", "bold");
        doc.setFontSize(40);
        doc.setTextColor("#000000");
        doc.text(data.flightsDetail.length > 2 ? data.flightsDetail[2]?.cfAirportOrigin : "", 3, 110);

        // FLIGHT NO TRANSIT-2
        doc.setFont("Poppins", "bold");
        doc.setFontSize(24);
        doc.setTextColor("#000000");
        doc.text(data.flightsDetail.length > 2 ? data.flightsDetail[2]?.cfNumber : "", 3, 118);

        // FLIGHT DATE TRANSIT-2
        doc.setFont("Poppins", "bold");
        doc.setFontSize(10);
        doc.setTextColor("#000000");
        doc.text(doc.splitTextToSize(data.flightsDetail.length > 2 ? formatDateNormal(data.flightsDetail[2]?.cfEstDepartureTime, "DD MMM HH:mm") : "", 12), 40, 114);

        // TRANSIT-3
        doc.setFont("Poppins", "bold");
        doc.setFontSize(10);
        doc.setTextColor("#6a6a6a");
        doc.text("TRANSIT-3", 53, 98);

        // TRANSIT-3 CITY
        doc.setFont("Poppins", "bold");
        doc.setFontSize(40);
        doc.setTextColor("#000000");
        doc.text(data.flightsDetail.length > 3 ? data.flightsDetail[3]?.cfAirportOrigin : "", 53, 110);

        // FLIGHT NO TRANSIT-3
        doc.setFont("Poppins", "bold");
        doc.setFontSize(24);
        doc.setTextColor("#000000");
        doc.text(data.flightsDetail.length > 3 ? data.flightsDetail[3]?.cfNumber : "", 53, 118);

        // FLIGHT DATE TRANSIT-2
        doc.setFont("Poppins", "bold");
        doc.setFontSize(10);
        doc.setTextColor("#000000");
        doc.text(doc.splitTextToSize(data.flightsDetail.length > 3 ? formatDateNormal(data.flightsDetail[3]?.cfEstDepartureTime, "DD MMM HH:mm") : "", 12), 90, 114);

        //BARCODE
        doc.addImage(newBarcode, "PNG", 2.5, 119, 100, 20, uuid(), "FAST", 0);

        // LION CARGO AGENT 3LC
        doc.setFont("Poppins", "bold");
        doc.setFontSize(19);
        doc.text(`LION PARCEL ${data.originCityCode}`, 53, 144.5, { align: "center" });


    };

    const transitsOrigin = []
    const transitFlight = []
    for (const item of data.flightsDetail) {
        transitsOrigin.push(`${item.cfAirportOrigin}`);
        transitFlight.push(item.cfNumber)
    }
    const transitOriginQr = transitsOrigin.length > 1 ? transitsOrigin.slice(1).join(",") : "";
    const transitFlightQr = transitFlight.join(",")
    let noPieces = 0;
    // duplicate label match number of total pieces
    for (let i = 0; i < data.cargoActualPiece; i++) {
        if (i > 0) {
            doc.addPage("a6", "p");
        }

        noPieces = i + 1;
        const newCombineQRText = `${data.cargoNumber}#${data.cargoNumber}${noPieces.toString().padStart(5, "0")}#${data.originCityCode}#${data.destinationCityCode}#${transitOriginQr}#${data.totalActualCargoGrossWeight}#${data.cargoActualPiece}#${data.nGenCreatedAt || data.createdAt}#${transitFlightQr};`;
        const newQRCode = await convertQRCode(newCombineQRText);
        const newBarcode = await convertJsBarcode(`${data.cargoNumber}${noPieces.toString().padStart(5, "0")}`);
        generate(noPieces, newQRCode, newBarcode);
    }

    doc.setProperties({
        title: `Cargo Label.pdf`
    });

      doc.autoPrint();

    window.open(doc.output("bloburl"), "_blank");
};

export default labelPlanePdfV2;
