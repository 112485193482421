
import { AccountController } from "@/app/ui/controllers/AccountController";
import { Vue } from "vue-class-component";
import generateLabel from "./label-pdf";
import generateLabelPlane from "./label-plane-pdf";
import generateLabelPlaneV2 from "./label-plane-v2-pdf";
import { CargoData } from "@/domain/entities/Cargo";
import { CargoController } from "@/app/ui/controllers/CargoController";
import JsBarcode from "jsbarcode";
import { convertQRCode } from "@/app/ui/modules/receipt/module";
import { convertDecimalWithComma } from "@/app/infrastructures/misc/Utils";
import formatValueNumber from "@/app/infrastructures/misc/common-library/FormatValueNumber";

import {
  ColumnManifestData,
  HeaderManifestData,
  SectionManifestData,
} from "@/domain/entities/MainApp";
import { generateManifestCargoV2 } from "./manifest-pdf-cargo-v2";
import capitalize from "lodash/capitalize";
import { FlagsUtilsCargo } from "@/feature-flags/flags-utils-cargo";
export default class Print extends Vue {
  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  async companyLogoPlaneLabel(prefix: any) {
    const logoLion = await import("@/app/ui/assets/images/logo-lion-air.png");
    const logoBatik = await import("@/app/ui/assets/images/logo-batik-air.png");
    const logoSuperjet = await import("@/app/ui/assets/images/logo-super-jet-air.png");
    const logoWings = await import("@/app/ui/assets/images/logo-wings-air.png");
    if (prefix.startsWith("JT")) {
      return logoLion.default;
    } else if (prefix.startsWith("ID")) {
      return logoBatik.default;
    } else if (prefix.startsWith("IU")) {
      return logoSuperjet.default;
    } else if (prefix.startsWith("IW")) {
      return logoWings.default;
    }
    return "";
  }

  getDetailCargo(cargoNumber: any, isPrint: boolean, name: string) {
    if (name) {
      return CargoController.getDetailRetryCargo({
        cargoNo: cargoNumber,
        isPrint: isPrint,
      });
    } else {
      return CargoController.getDetailCargo({
        cargoNo: cargoNumber,
        isPrint: isPrint,
      });
    }
  }

  async printData(cargoNumber: any, type: string, name: string) {
    const isPrint = true;
    const cargo: CargoData = await this.getDetailCargo(cargoNumber, isPrint, name);
    cargo.totalActualCargoGrossWeight = this.formatValueNumber(cargo.totalActualCargoGrossWeight);
    cargo.totalActualCargoVolumeWeight = this.formatValueNumber(cargo.totalActualCargoVolumeWeight);
    if (cargo.cargoNumber) {
      // barcode

      JsBarcode("#barcode", cargo.cargoNumber, {
        textAlign: "left",
        displayValue: false,
        fontSize: 16,
      });
    
      // qr code
      const combineQRText = `${cargo.cargoNumber}#${cargo.vehiclePoliceNumber}#${cargo.originCityCode} - ${cargo.originCityName}#${cargo.destinationCityCode} - ${cargo.destinationCityName}#${cargo.airportDestinationCityCode} - ${cargo.airportDestinationCityName}#${cargo.cargoType}#${cargo.partnerName}#${cargo.cargoCommodity}#${cargo.cargoProduct}#${cargo.totalStt}#${cargo.cargoActualPiece}#${cargo.totalGrossWeight}#${cargo.totalVolumeWeight}`;
      const QRCode = await convertQRCode(combineQRText);

      // logo
      const element: any = document;
      const companyLogo = element.getElementById("company-logo").src;
      const barcode = element.getElementById("barcode");
      const companyLogoPlaneLabel = await this.companyLogoPlaneLabel(
        cargo.vehiclePoliceNumber
      );

      // mapping data
      const data: any[] = [];

      cargo.detailStt.forEach((stt, index) =>
        data.push({
          no: index + 1,
          bagNo: stt.baggingVendorNumber || stt.baggingNumber || "-",
          sttNo: stt.sttNumber,
          sttElexysNo: stt.sttElexysNumber,
          pieces: stt.totalPieces,
          grossWeight: `${convertDecimalWithComma(stt.grossWeigth, 2)} Kg`,
          volumeWeight: `${convertDecimalWithComma(stt.volumeWeight, 2)} Kg`,
          productType: stt.productType,
          commodity: stt.commodity,
          origin: stt.origin,
          destination: stt.destination,
        })
      );
      if (type === "label" && cargo.cargoType !== "plane")
        await generateLabel(cargo, companyLogo, barcode, QRCode);
      else this.labelPlane(cargo, companyLogoPlaneLabel, barcode);
    }
  }

  async labelPlane(cargo: CargoData, companyLogoPlaneLabel: string, barcode: any) {
    if (FlagsUtilsCargo.new_label_v2.isEnabled()) {
      await generateLabelPlaneV2(cargo, companyLogoPlaneLabel)
    } else {
      await generateLabelPlane(cargo, companyLogoPlaneLabel, barcode)
    }
  }

  formatValueNumber(number: any) {
    if (typeof number === "string") {
      const convert = +number.replace(",", ".");
      return formatValueNumber(convert);
    }
    return formatValueNumber(number);
  }

  printManifestV2 = async (id: number) => {
    const dataManifest = await this.getDetailCargo(id, true, "");
    if (dataManifest) {
      dataManifest.totalActualCargoGrossWeight = this.formatValueNumber(dataManifest.totalActualCargoGrossWeight);
      dataManifest.totalActualCargoVolumeWeight = this.formatValueNumber(dataManifest.totalActualCargoVolumeWeight);
      JsBarcode("#barcode", dataManifest.cargoNumber, {
        textAlign: "left",
        displayValue: false,
        fontSize: 16,
      });
      const element: any = document;
      const barcode = element.getElementById("barcode").src;
      // qr code
      const combineQRText = `${dataManifest.cargoNumber}#${dataManifest.vehiclePoliceNumber}#${dataManifest.originCityCode} - ${dataManifest.originCityName}#${dataManifest.destinationCityCode} - ${dataManifest.destinationCityName}#${dataManifest.airportDestinationCityCode} - ${dataManifest.airportDestinationCityName}#${dataManifest.cargoType}#${dataManifest.partnerName}#${dataManifest.cargoCommodity}#${dataManifest.cargoProduct}#${dataManifest.totalStt}#${dataManifest.cargoActualPiece}#${dataManifest.totalGrossWeight}#${dataManifest.totalVolumeWeight}`;
      const QRCode = await convertQRCode(combineQRText);
      const data = new SectionManifestData({
        featureName: `Cargo Manifest [${dataManifest.cargoNumber}]`,
        headerDetailData: new HeaderManifestData({
          originCity: `${dataManifest.originCityCode} - ${dataManifest.originCityName}`,
          destinationCity: `${dataManifest.destinationCityCode} - ${dataManifest.destinationCityName}`,
          cargoType: capitalize(dataManifest.cargoType),
          totalStt: dataManifest.totalStt,
          partnerName: dataManifest.partnerName,
          departureDate: dataManifest.departureDate,
          arrivalDate: dataManifest.arrivalDate,
          cargoCommodity: dataManifest.cargoCommodity,
          cargoProduct: dataManifest.cargoProduct,
          totalPiece: dataManifest.cargoActualPiece,
          totalActualCargoVolumeWeight: dataManifest.totalActualCargoVolumeWeight,
          totalActualCargoGrossWeight: dataManifest.totalActualCargoGrossWeight,
          totalGrossWeight: dataManifest.totalGrossWeight,
          totalVolumeWeight: dataManifest.totalVolumeWeight,
          updatedAt: dataManifest.updatedAt,
          updatedBy: dataManifest.updatedBy,
        }),
        headerSection: [
          "originCity",
          "destinationCity",
          "cargoType",
          "partnerName",
          "departureDate",
          "arrivalDate",
          "cargoCommodity",
          "cargoProduct",
          "totalStt",
          "totalPiece",
          "totalGrossWeight",
          "totalVolumeWeight",
        ],
        data: dataManifest.detailStt.map(
          (stt: any) =>
           new ColumnManifestData({
              bagNo: stt.baggingVendorNumber || stt.baggingNumber || "-",
              sttNo: stt.sttNumber,
              totalGrossWeight: stt.grossWeigth,
              destinationCity: stt.destination,
            })
        ),
        totalColumnHeader: 2,
        signature: true,
      });
      generateManifestCargoV2(data, barcode, QRCode);
    }
  };

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }
}
