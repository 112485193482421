import moment from "moment";
moment.locale("en");

const dateTimeLabelPlane = (date: string) => {
  if (!date) return "";
  return `${moment(date).format("DD-MMM-YYYY")} ETD:${moment(date).format(
    "HH:mm"
  )}`;
};
export default dateTimeLabelPlane;
