import { jsPDF } from "jspdf";
import { CargoData } from "@/domain/entities/Cargo";
import { cargoRect, cargoTypeData, productRect } from "./cargo-module";
import {
  convertDecimalWithComma,
  dateToDateString,
  ellipsisString
} from "@/app/infrastructures/misc/Utils";
import formatValueNumber from "@/app/infrastructures/misc/common-library/FormatValueNumber";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { createI18n } from "vue-i18n";
import { AccountController } from "@/app/ui/controllers/AccountController";

const labelPdf = async (
  data: CargoData,
  logo: string,
  barcode: string,
  qrcode: string
) => {
  const i18nInstance = createI18n({
    legacy: false,
    locale: AccountController.accountData.account_type_detail.countryCode.toLowerCase(),
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  const translate = i18nInstance.global.t;
  const doc: any = new jsPDF("p", "mm", "a6", true);

  const generate = (pieceCount: number) => {
    doc.addImage(logo, "PNG", 7, 5, 25, 5, "logo", "FAST", 0);
    doc.addImage(barcode, "PNG", 4, 22, 54, 14, "barcode", "FAST", 0);
    doc.addImage(qrcode, "PNG", 60, 89, 41, 43, "qrcode", "FAST", 0);

    doc.setLineWidth(0.3);
    doc.rect(1, 1, 103, 146);

    doc.line(1, 13, 104, 13);
    doc.line(57, 1, 57, 13);

    // Type Cargo
    doc.setFont("helvetica", "bold");
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(63, 4, cargoRect(data.cargoType), 6, 1, 1, "FD");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(8);
    doc.text(
      translate(`KARGO ${cargoTypeData(data.cargoType)?.label?.toUpperCase()}`),
      65,
      8
    );

    // Pos Name
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(10);
    doc.text(ellipsisString(data.partnerName, 41), 5, 20);

    // Type Product
    doc.setFont("helvetica", "bold");
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(
      63,
      23,
      productRect(data.cargoProduct.toUpperCase()),
      6,
      1,
      1,
      "FD"
    );
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(8);
    doc.text(data.cargoProduct.toUpperCase(), 65, 27);

    // Barcode Value
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(10);
    doc.text(data.vehiclePoliceNumber, 63, 34);

    // Bagging No
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(20);
    doc.text(data.cargoNumber, 5, 43);

    // Commodity
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(8);
    doc.text(translate("Komoditas"), 5, 49);

    // Commodity Value
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(10);
    doc.text(data.cargoCommodity, 5, 54);

    // Origin City
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(8);
    doc.text(translate("Kota Asal"), 5, 62);

    // Origin City Value
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(34);
    doc.text(data.originCityCode, 5, 74);

    // Origin City Date
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(8);
    doc.text(`${dateToDateString(data.departureDate, true, true)}`, 5, 79);

    // Destination City
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(8);
    doc.text(translate("Kota Tujuan"), 63, 62);

    // Destination City Value
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(34);
    doc.text(data.destinationCityCode, 63, 74);

    // Destination City Date
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(8);
    doc.text(`${dateToDateString(data.arrivalDate, true, true)}`, 63, 79);

    // Total STT
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(7);
    doc.text("Total STT", 5, 89.5);

    // Total STT
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(7);
    doc.text(`: ${data.totalStt}`, 48, 89.5);

    // Total Koli
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(7);
    doc.text(translate("Koli Ke"), 5, 95);

    // Total Koli
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(7);
    doc.text(`: ${pieceCount}/${data.cargoActualPiece}`, 48, 95);

    // Total Berat Kotor
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(7);
    doc.text(translate("Total Berat Kotor"), 5, 100.5);

    // Total Berat Kotor
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(7);
    doc.text(
      `: ${convertDecimalWithComma(data.totalGrossWeight, 2)} Kg`,
      48,
      100.5
    );

    // Total Berat Dimensi
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(7);
    doc.text(translate("Total Berat Dimensi"), 5, 106);

    // Total Berat Dimensi
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(7);
    doc.text(
      `: ${convertDecimalWithComma(data.totalVolumeWeight, 2)} Kg`,
      48,
      106
    );

    // Aktual Total Berat Kotor Kargo
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(7);
    doc.text(translate("Aktual Total Berat Kotor Kargo"), 5, 111.5);

    // Aktual Total Berat Kotor Kargo
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(7);
    doc.text(`: ${data.totalActualCargoGrossWeight} Kg`, 48, 111.5);

    // Aktual Total Berat Dimensi Kargo
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(7);
    doc.text(translate("Aktual Total Berat Dimensi Kargo"), 5, 117);

    // Aktual Total Berat Dimensi Kargo
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(7);
    doc.text(`: ${data.totalActualCargoVolumeWeight} Kg`, 48, 117);

    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(6);
    const splitFooter = doc.splitTextToSize(
      translate(
        "Terjadi kendala pada proses pengiriman? Hubungi Customer Service 021-80820072 atau hubungi Kota Asal yang bersangkutan."
      ),
      80
    );
    doc.text(splitFooter, 5, 140);

    doc.line(1, 57, 104, 57);
    doc.line(1, 83, 104, 83);
    doc.line(1, 134, 104, 134);
  };

  // duplicate label match number of total pieces
  for (let i = 0; i < data.cargoActualPiece; i++) {
    if (i > 0) {
      doc.addPage("a6", "p");
    }
    generate(i + 1);
  }

  doc.setProperties({
    title: `Cargo Label.pdf`
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
};

export default labelPdf;
